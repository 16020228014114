import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import logo from './media/full.gif';
import tg from './media/tg.png'
import tw from './media/tw.png'
import db from './media/db.png'
import dx from './media/dx.png'
import td from './media/td.gif'
import gif from './media/gif.gif'
import eth from './media/eth.png'
import ab from './media/about.png'
import audioFile from './media/audio.mp3';
import './App.css';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  // Use useEffect to show the modal when the component mounts
  useEffect(() => {
    setModalVisible(true);
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  return (
    <div className="App">
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y" src={gif}></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z" src={eth}></img>
        </div>
      </div>
      <body>
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}
      <h1 className='top front'>Mr. Peebles</h1>
        <div className='container front'>
          <a className='card'>
            <img src={logo} className="App-logo" alt="logo" width='500vw'/>
          </a>
        </div>
        <h1 className='top front'>HE IS REAL.</h1>
        <header className="App-header">
        <div className='container front'>
          <div className='item'>
            <a href='#'><img src={tg} width='120px'/></a>
          </div>
          <div className='item'>
            <a href='https://twitter.com/MrPeeblesSol'><img src={tw} width='120px'/></a>
          </div>
          <div className='item'>
            <a href='https://t.me/MrPeeblesSol' ><img src={dx} width='105px'/></a>
          </div>
        </div>
      </header>
        <h1 className='bottom front'>Real MR PEEBLES</h1>
        <div className='front about'>
            <a href='https://knowyourmeme.com/memes/monday-left-me-broken-cat-mr-peebles-singing-yellow-eyed-cat' className='fairy'><img src={ab} width='300px'/></a>
        </div>
        <h1 className='bottom front'>HE IS ON HIS WAY</h1>
        <img src={td} width='500vw' className='front'/>
        <h1 className='bottom front'>Monday left me broken <br/> Tuesday, I was through with hoping <br/> <br/> Wednesday, my empty arms were open <br/> <br/> Thursday, waiting for love, waiting for love <br/> </h1>
        <img src={db} width='500vw' className='front'/>
        <h1 className='top front'>meme (funy)</h1>
        <p className='title front'>MINT REVOKED, LP BURNED.<br/>true community token</p>
        <p className='fairy'>Monday Left me broken</p>
      </body>
    </div>
  );
}

export default App;
